<template>
  <page-content :show-search="false">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        search-tips="搜尋 設備Token / 設備名稱"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskPeripheralMode',
  data() {
    return {
      title: common.getMenuName('kioskPeripheral'),
      // view_permission: common.checkPermission('Kiosk_NFC_List'),
      view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      status_map: [],
      header_actions: [],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: '設備 Token',
          field: 'kioskToken',
          width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: '設備名稱',
          field: 'kioskName',
          width: '290px',
        },
        // {
        //   label: 'common.company',
        //   field: 'companyName',
        //   width: '200px',
        // },
        // {
        //   label: 'common.facility',
        //   field: 'facilityName',
        //   width: '200px',
        // },
        {
          label: '外設模式',
          field: 'deviceMode',
          // width: '110px',
        },
        // {
        //   label: '固定外設類型（合計）',
        //   field: 'boundPurposes',
        //   width: '380px',
        //   changeHTML: (raw, _row) => {
        //     return raw.map(val => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal">${val}</span>`)
        //       .join('')
        //   }
        // },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          // permission: 'Kiosk_NFC_Edit'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          // clearable: false,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          // clearable: false,
        },
      ],
      search_default: {
        companyID: null,
        facilityID: null,
      },
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      // common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      // common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    // common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    this.getList()
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      // const api = '/kiosk/WeHealth/GetKioskPeripheralModeList'
      const api = '/kioskmanage/GetKioskDevices'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.items
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskPeripheral_edit',
        params: { id: common.encrypt(data.kioskToken) }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  }
}
</script>

<style lang="scss">

</style>
